import React, { useState, useEffect } from "react";
import { Header, Frame, Print, Loading } from "../components/";
import { useLocation, useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import { where, getDocs, query, collection } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import Cabecalho from "../assets/cabecalho.png";
import { Nome } from "../components/Formatar";
import moment from "moment";

const Recibo = () => {
  const navigate = useNavigate();

  const e = require("numero-por-extenso");

  let uid = useLocation().state.uid;
  const mes = useLocation().state.mes;

  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  const Query = async () => {
    const qry = await getDocs(
      query(collection(db, "recibos"), where("uidCliente", "==", uid))
    );

    qry.forEach((doc) => {
      if (doc.data().mensalidade === mes) setDados(doc.data());
    });

    setLoading(false);
  };

  const GetDados = async () => {
    if (uid) Query();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          Query();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    GetDados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      {loading && <Loading />}

      {!loading && (
        <Frame
          body={
            <div className="text-center printRecibo">
              <div className="mt-3">
                <img
                  src={Cabecalho}
                  alt="Expresso Embaixador"
                  className="img-fluid"
                />
              </div>
              <div className="my-5 text-end">
                <h6>Recibo {dados.id}</h6>
              </div>

              <div className="my-5 justify">
                <h6>
                  Recebemos de {dados.nomeCliente.toUpperCase()}, inscrito(a) no
                  CPF sob o número {dados.documentoCliente}, a quantia supra de{" "}
                  {e.porExtenso(dados.valorLiquido, e.estilo.monetario)},
                  referente a quitação da mensalidade do serviço de fretamento
                  escolar.
                </h6>
              </div>
              <div className="my-5 table-responsive">
                <table className="table table-sm small">
                  <thead>
                    <tr>
                      <th className="d-none d-sm-table-cell d-print-table-cell">
                        Usuário
                      </th>
                      <th className="d-none d-sm-table-cell d-print-table-cell">
                        Produto
                      </th>
                      <th>Fatura</th>
                      <th>Valor</th>
                      <th>Desconto</th>
                      <th>Total</th>
                      <th>Qtde</th>
                    </tr>
                  </thead>
                  <tbody className="small">
                    <tr>
                      <td className="text-capitalize d-none d-sm-table-cell d-print-table-cell">
                        {Nome(dados.nomeCliente)}
                      </td>
                      <td className="d-none d-sm-table-cell d-print-table-cell">
                        Mensalidade
                      </td>
                      <td>
                        {moment(dados.mensalidade, "MMYY").format("MM/YYYY")}
                      </td>
                      <td>
                        R$ {(+dados.valorBruto).toFixed(2).replace(".", ",")}
                      </td>
                      <td>
                        R$ {(+dados.valorDesconto).toFixed(2).replace(".", ",")}
                      </td>
                      <td>
                        R$ {(+dados.valorLiquido).toFixed(2).replace(".", ",")}
                      </td>
                      <td>{dados.quantidade}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="my-5">
                <h6>
                  Pelotas, {moment(dados.dhEmissao).format("DD/MM/YYYY")}.
                </h6>
              </div>
              <Print />
            </div>
          }
        />
      )}
    </>
  );
};

export default Recibo;
