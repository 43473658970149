import { useState, useEffect } from "react";
import { Frame, Input, Loading } from "../components";
import { ValidaEmail, ValidaSenha, ValidaLogin } from "../components/Validacao";
import { auth } from "../config/firebase";
import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);
  const [erro, setErro] = useState({});

  const navigate = useNavigate();

  const Logado = () => {
    const uid = auth.currentUser?.uid;

    if (uid) navigate("../home");
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) navigate("../home");
      });
  };

  const HandleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const erroEmail = ValidaEmail(email);
    const erroSenha = ValidaSenha(senha);

    if (erroEmail || erroSenha) {
      setErro({ email: erroEmail, senha: erroSenha });
      setLoading(false);
    } else {
      signInWithEmailAndPassword(auth, email, senha)
        .then(() => {
          navigate("../home");
        })
        .catch((err) => {
          setErro(ValidaLogin(err.code));
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    Logado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Frame
        body={
          <div className="px-3">
            <form autoComplete="off" onSubmit={HandleSubmit} noValidate>
              <div className="row mt-2">
                <Input
                  id="email"
                  label="Email"
                  type="email"
                  className={erro.email && "is-invalid"}
                  placeholder="Digite seu email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  erro={erro.email}
                  autoFocus
                />
              </div>
              <div className="row mt-2">
                <Input
                  id="senha"
                  label="Senha"
                  type="password"
                  className={erro.senha && "is-invalid"}
                  placeholder="Digite sua senha"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                  erro={erro.senha}
                />
              </div>
              <div className="mt-1 text-start">
                <small>
                  <a href="/redefinir" className="dark">
                    Esqueci minha senha
                  </a>
                </small>
              </div>
              <div className="row mt-3">
                <button type="submit" className="btn btn-primary ">
                  LOGIN
                </button>
              </div>
            </form>
          </div>
        }
        footer={
          <div>
            <hr />

            <div className="mt-1">
              Não é cadastrado?{" "}
              <a href="cadastro" className="text-dark">
                Cadastre-se aqui
              </a>
            </div>
          </div>
        }
        title="Faça login para continuar"
        logo
        copyright
      />
    </>
  );
};

export default Login;
