import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input, Select } from "../components";
import { useNavigate } from "react-router-dom";
import { ValidaForm1 } from "../components/Validacao";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Form1 = () => {
  const [dados, setDados] = useState({});
  const [erro, setErro] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDados({ ...dados, [name]: value });
  };

  const setData = async () => {
    const email = auth?.currentUser?.email;

    await setDoc(
      doc(db, "ebserh2024", uid),
      { ...dados, email: email, uid: uid },
      { merge: true }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const err = ValidaForm1(dados);

    if (err) {
      setErro(err);
      setLoading(false);
    } else {
      setData().then(() => navigate("../form2"));
    }
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "ebserh2024", uid));

    const data = qry.data();

    if (data?.status) {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      <Frame
        body={
          <form
            className="text-start"
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
          >
            <div className="row mt-2">
              <div className="col-sm-12">
                <Input
                  id="nome"
                  label="Nome Completo"
                  type="text"
                  className={erro.nome && "is-invalid"}
                  placeholder="Conforme seu documento"
                  value={dados?.nome || ""}
                  onChange={handleChange}
                  erro={erro.nome}
                  autoFocus
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-6">
                <Input
                  id="cpf"
                  label="CPF"
                  type="number"
                  className={erro.cpf && "is-invalid"}
                  placeholder="Digite seu CPF"
                  value={dados?.cpf || ""}
                  onChange={handleChange}
                  erro={erro.cpf}
                />
              </div>
              <div className="col-sm-6">
                <Input
                  id="rg"
                  label="RG"
                  type="number"
                  className={erro.rg && "is-invalid"}
                  placeholder="Digite seu RG"
                  value={dados?.rg || ""}
                  onChange={handleChange}
                  erro={erro.rg}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-sm-4">
                <Input
                  id="fone1"
                  label="Fone 1"
                  type="number"
                  className={erro.fone1 && "is-invalid"}
                  placeholder="Digite seu telefone"
                  value={dados?.fone1 || ""}
                  onChange={handleChange}
                  erro={erro.fone1}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  id="fone2"
                  label="Fone 2"
                  type="number"
                  className={erro.fone2 && "is-invalid"}
                  placeholder="Digite seu telefone"
                  value={dados?.fone2 || ""}
                  onChange={handleChange}
                  erro={erro.fone2}
                />
              </div>
              <div className="col-sm-4">
                <Select
                  id="vinculo"
                  label="Vínculo"
                  onChange={handleChange}
                  size="col-sm-4"
                  value={dados?.vinculo}
                  erro={erro.vinculo}
                  options={
                    <>
                      <option value=""></option>
                      <option value="estudante">Estudante</option>
                      <option value="professor">Professor</option>
                      <option value="servidor">Servidor</option>
                    </>
                  }
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12">
                <Input
                  id="endereco1"
                  label="Endereço"
                  type="text"
                  className={erro.endereco1 && "is-invalid"}
                  placeholder="Digite seu endereço"
                  value={dados?.endereco1 || ""}
                  onChange={handleChange}
                  erro={erro.endereco1}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-4">
                <Input
                  id="numero1"
                  label="Número"
                  type="text"
                  className={erro.numero1 && "is-invalid"}
                  placeholder="000"
                  value={dados?.numero1 || ""}
                  onChange={handleChange}
                  erro={erro.numero1}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  id="bairro1"
                  label="Bairro"
                  type="text"
                  className={erro.bairro1 && "is-invalid"}
                  placeholder="Digite o bairro"
                  value={dados?.bairro1 || ""}
                  onChange={handleChange}
                  erro={erro.bairro1}
                />
              </div>
              <div className="col-sm-4">
                <Input
                  id="cep1"
                  label="CEP"
                  type="number"
                  className={erro.cep1 && "is-invalid"}
                  placeholder="Digite o CEP"
                  value={dados?.cep1 || ""}
                  onChange={handleChange}
                  erro={erro.cep1}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-sm-12">
                <Input
                  id="complemento1"
                  label="Complemento"
                  type="text"
                  className={erro.complemento1 && "is-invalid"}
                  placeholder="Ponto de referência (opcional)"
                  value={dados?.complemento1 || ""}
                  onChange={handleChange}
                  erro={erro.complemento1}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-sm-12 d-grid">
                <button type="submit" className="btn btn-primary">
                  ENVIAR
                </button>
              </div>
            </div>
          </form>
        }
        title="Dados Cadastrais"
      />
    </>
  );
};

export default Form1;
