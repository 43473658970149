import moment from "moment";
import "moment/locale/pt-br";
import Logo from "../assets/logo.png";

const VerContrato = (props) => {
  const dados = props.dados;

  return (
    <div className="justify print m-3">
      <div className="my-4 text-center">
        <img src={Logo} alt="Logo Embaixador" width={250} />
      </div>
      <div>
        <p>
          <b>CONTRATADA: EXPRESSO EMBAIXADOR LTDA.</b>, pessoa jurídica com sede
          à Rua Giuseppe Garibaldi, nº 660, na cidade de Pelotas/RS, inscrita no
          CNPJ Nº 92.189.612/0001-92, e inscrição estadual nº 093/0180615.
        </p>
        <p>
          <b>CONTRATANTE: {dados.nome.toUpperCase()}</b>, residente à{" "}
          {dados.endereco1}, {dados.numero1} na cidade de Pelotas, CPF Nº{" "}
          {dados.cpf}, RG Nº
          {dados.rg}, Categoria: {dados.vinculo}.
        </p>
        <p>
          As partes acima qualificadas ajustam entre si o presente contrato,
          mediante as condições e cláusulas que seguem:
        </p>
        <div className="recuo">
          <p>
            <b>1. DO OBJETO</b>
          </p>
          <p>
            <b>1.1 </b> Por adesão da CONTRATANTE ao presente contrato, a
            CONTRATADA disponibilizará o serviço de transporte na modalidade
            Fretamento Urbano/Metropolitano Contínuo, conforme autorizado pela
            METROPLAN, tendo como origem a cidade de Pelotas/RS e destino final
            a cidade de Rio Grande/RS, de segunda à sexta-feira, menos feriados,
            em horários pré-definidos pela CONTRATADA, mediante ajuste entre as
            partes.
          </p>
          <p>
            <b>1.2 </b> O transporte objeto deste contrato iniciará com o
            recolhimento da parte CONTRATANTE no perímetro urbano da cidade de
            Pelotas em ponto de embarque/desembarque, sito à Avenida Duque de
            Caxias - ao lado da antiga fábrica da Coca Cola, na cidade de
            Pelotas, local em que será designado veículo para realizar o
            transporte até o destino final, Hospital Universitário, à rua
            Visconde de Paranaguá, nº 102 - Centro, na cidade Rio Grande.
          </p>
          <p>
            <b>2. DO PREÇO E CONDIÇÕES DE PAGAMENTO</b>
          </p>
          <p>
            <b>2.1 </b> Pelo uso do serviço objeto deste contrato será cobrado o
            valor à título de mensalidade no total de R$ 620,00 (seiscentos e
            vinte reais).
          </p>
          <p>
            <b>2.2 </b> EXCLUSIVAMENTE no mês de assinatura do contrato o valor
            da mensalidade poderá ser apurado mediante apreciação proporcional
            do uso.
          </p>
          <p>
            <b>2.3 </b>O pagamento de cada mensalidade poderá ser realizado:
          </p>
          <p>
            <b>a) </b> Via PIX, com comprovação de transferência mediante anexo
            no Portal do Fretamento
            (https://ebserh2024.expressoembaixador.com.br/);
          </p>
          <p>
            <b>b) </b> Na sede da CONTRATADA em Pelotas/RS, ou ao preposto da
            empresa, nas seguintes modalidades em dinheiro, PIX, boleto bancário
            ou cartão VISA, MASTER, ELO e BANRICOMPRAS, nas modalidades débito
            ou crédito em até 3 (três parcelas), com parcela mínima não inferior
            a R$ 50,00 cinquenta reais).
          </p>
          <p>
            <b>2.4 </b>
            Caso não ocorra o pagamento da mensalidade até o dia 10 de cada mês,
            será emitido boleto bancário no valor total de mensalidade em nome
            da CONTRATANTE, cujo vencimento se dará ao primeiro dia útil
            seguinte ao prazo de vencimento, ressalvado o pagamento e sua
            efetiva comprovação por qualquer outro meio indicado neste contrato.
          </p>
          <p>
            <b>2.5 </b> A hipótese acima configurará mora para a CONTRATANTE,
            ensejando a incidência de encargos legais sobre o valor da
            mensalidade não paga, e a CONTRATANTE poderá levar o presente termo
            a protesto, observada a legislação processual vigente.
          </p>
          <p>
            <b>2.6 </b> A ausência de pagamento implicará a suspensão do serviço
            até que ocorra o efetivo pagamento de mensalidade vencida (e, sendo
            o caso, da vincenda), inclusive com a eventual incidência de
            encargos legais.
          </p>
          <p>
            <b>2.7 </b> O valor de mensalidade estipulado na cláusula 2.1 poderá
            ser corrigido em caso de aprovação de reajuste tarifário aprovado
            pela METROPLAN, poder concedente, o que será aplicado, ou não,
            limitado à porcentagem de aumento, mediante melhor opção da
            CONTRATADA e condicionado ao aviso prévio de 30 dias de aumento da
            mensalidade, resguardando-se os direitos da CONTRATANTE.
          </p>
          <p>
            <b>3. DO USO DO SERVIÇO</b>
          </p>
          <p>
            <b>3.1 </b> O serviço ordinário se dará a partir da disponibilização
            de 40 passagens (créditos em carteira digital) mensais, que serão
            atualizados mediante pagamento de mensalidade, com valores
            individualizados proporcionais ao montante pago, de acordo com a
            cláusula 2.
          </p>
          <p>
            <b>3.2 </b> A CONTRATADA confeccionará uma carteira digital e/ou
            cartão magnético de identificação diferenciada para a CONTRATANTE, a
            qual obrigatoriamente deverá ser apresentada ao preposto da
            CONTRATADA no momento do embarque do veículo que o levará até o
            destino final.
          </p>
          <p>
            <b>3.3 </b> A cada embarque, a CONTRATANTE será descontado um
            crédito na carteira digital quando apresentada ao preposto da
            empresa.
          </p>
          <p>
            <b>3.4 </b> A disponibilização/atualização dos créditos fica
            condicionada ao efetivo pagamento da mensalidade, nos termos da
            cláusula 2, com no mínimo um dia de antecedência por parte da
            CONTRATANTE.
          </p>
          <p>
            <b>3.5 </b> A carteira digital com créditos será nominal e
            intransferível, sendo estes válidos tão somente ao mês a que
            corresponde a mensalidade.
          </p>
          <p>
            <b>3.6 </b> Até a data de vencimento da mensalidade seguinte,
            estipuladas na cláusula 2.1, a CONTRATANTE poderá utilizar créditos
            sobressalientes que eventualmente não tenham sido utilizadas no mês
            anterior, limitadas ao número de 10 créditos. Nessa hipótese, o
            número de créditos utilizado será compensado no momento da
            emissão/atualização da mensalidade subsequente.
          </p>
          <p>
            <b>3.7 </b>
            Em caso de dificuldade de acesso, e por questões de logística
            visando a melhor realização do serviço, a CONTRATADA está autorizada
            a indicar local exato de embarque/desembarque, hipótese em que a
            CONTRATANTE não poderá se opor.
          </p>
          <p>
            <b>3.8 </b>
            Para melhor realização do serviço, a CONTRATANTE deverá estar
            disponível em 10 minutos de antecedência ao horário de recolhimento
            previsto.
          </p>
          <p>
            <b>3.9 </b>
            Após o decurso do prazo de tolerância a CONTRATADA fica desobrigada
            a retornar ao local de recolhimento naquela data, independentemente
            da causa.
          </p>
          <p>
            <b>3.10 </b> Visando a melhor realização do serviço, os horário e
            trajetos de recolhimento e entrega nas regiões indicadas no momento
            da contratação, serão estabelecidos exclusivamente pela CONTRATADA,
            que deverá comunicar o horário exato de recolhimento da CONTRATANTE,
            a qual, por sua vez, não poderá se opor ao cronograma.
          </p>
          <p>
            <b>3.11 </b> Em períodos especiais e excepcionais, como o de exames,
            a CONTRATANTE será obrigada a comunicar a CONTRATADA sua vontade de
            utilizar o serviço, tanto para a ida como para retorno, em até 24
            (vinte e quatro) horas de antecedência.
          </p>
          <p>
            <b>3.12 </b> Em caso de problemas mecânicos, a CONTRATADA deverá
            substituir o veículo em até uma hora.
          </p>
          <p>
            <b>3.13 </b>
            Em caso de sinistro, greve (mesmo nos casos em que não haja adesão
            da totalidade dos clientes deste fretamento) e/ou casos que não
            sejam por culpa e/ou responsabilidade da CONTRATADA ou seu preposto,
            não haverá nenhum tipo de abatimento, abono, indenização e/ou
            ressarcimento em favor da CONTRATANTE.
          </p>
          <p>
            <b>3.14 </b> De forma extraordinária, a CONTRATANTE poderá utilizar
            do transporte regular na linha 271 do METROPLAN (Pelotas x Rio
            Grande x Pelotas) atendida pela CONTRATADA, de segunda a
            sexta-feira, na modalidade comum (menos domingos e feriados).
          </p>
          <p>
            <b>3.15 </b> No ato de embarque da utilização extraordinária, a
            CONTRATANTE deverá apresentar sua carteira digital com créditos
            válidos EXCLUSIVAMENTE AO PREPOSTO DA CONTRATADA NA PORTA DO ÔNIBUS,
            sendo impossível a aquisição de bilhete de passagem em guichê de
            estação rodoviária com os créditos de carteira digital descrita nas
            cláusulas 3.1 e 3.2.
          </p>
          <p>
            <b>3.16 </b> Após conferência da carteira digital, será emitido o
            bilhete de passagem eletrônico (BPe embarcado) em valor equivalente
            ao da passagem adquirida nos termos da cláusula 3.1 deste contrato,
            no trajeto eleito pela parte CONTRATANTE dentro da linha
            271-METROPLAN, Pelotas x Rio Grande x Pelotas.
          </p>
          <p>
            <b>3.17 </b>
            Caso a CONTRATANTE não apresente a carteira digital do fretamento no
            momento em que utilizar a linha regular, será cobrada a diferença
            entre o valor da passagem adquirida nos termos da cláusula 3.1 e o
            valor da tarifa da linha regular, conforme tabela vigente da
            METROPLAN ao momento do uso.
          </p>
          <p>
            <b>4. DO PRAZO DE VIGÊNCIA DO CONTRATO</b>
          </p>
          <p>
            <b>4.1 </b> O presente contrato terá vigência a contar de sua
            assinatura até 31/07/2025, e torna sem efeito qualquer contratação
            anterior entre as partes.
          </p>
          <p>
            <b>4.2 </b> O contrato poderá ser renovado mediante simples
            manifestação das partes, mediante aceite no Portal do Fretamento,
            seguida do devido pagamento da mensalidade correspondente, nos
            termos da cláusula 2.
          </p>
          <p>
            <b>4.3 </b> Após o escoamento dos prazos previstos nas cláusulas
            acima, eventual renovação estará sujeita à manifestação das partes,
            ressalvado reajuste de preço, respeitando-se o aumento de tarifa
            aprovado pela METROPLAN (poder concedente).
          </p>
          <p>
            <b>5. DAS HIPÓTESES DE RESCISÃO</b>
          </p>
          <p>
            <b>5.1 </b>O presente contrato poderá ser rescindido na seguintes
            hipóteses, sem qualquer prejuízo às partes:
          </p>
          <p>
            <b>a) </b> MANIFESTAÇÃO EXPRESSA: A CONTRATANTE poderá informar seu
            interesse em rescindir o contrato, respeitada a antecedência mínima
            de 30 (trinta) dias.
          </p>
          <p>
            <b>b) </b> JUSTA CAUSA: Quando a CONTRATANTE desrespeitar em três ou
            mais ocasiões as disposições gerais de uso e prestação do serviço,
            conforme cláusula 3.
          </p>
          <p>
            <b>c) </b>INADIMPLÊNCIA: Quando houver o atraso no pagamento de duas
            ou mais mensalidades, em desacordo com a cláusula 2, haverá rescisão
            automática.
          </p>
          <p>
            <b>5.2 </b>
            Nas hipóteses A, B e C acima estipuladas, a CONTRATANTE não se exime
            do pagamento da mensalidade em que se deu a rescisão e, sempre que
            se concretizar a mora prevista na cláusula 2.5, estará a CONTRATADA
            autorizada a utilizar dos meios adequados de cobrança caso existam
            valores pendentes.
          </p>
          <p>
            <b>5.3 </b> Em casos extraordinários, como o afastamento por doença
            ou por licença maternidade/paternidade, o CONTRATANTE, mediante a
            devida comprovação, terá o direito de , ou suspender o contrato pelo
            prazo máximo de 20 dias, ou rescindi-lo contrato sem nenhum
            pagamento e/ou prejuízo.
          </p>
          <p>
            <b>5.4 </b> Fica autorizada a rescisão sempre que houver alterações
            nos termos de uso do presente contrato, dispensado qualquer tipo de
            pagamento.
          </p>
          <p>
            <b>5.5 </b> Em caso de rescisão, a readmissão do CONTRATANTE estará
            condicionada à disponibilidade de vagas, de modo que a CONTRATADA
            está desobrigada a autorizar o retorno imediato em caso de
            indisponibilidade.
          </p>
          <p>
            <b>5.6 </b> A CONTRATADA fica desobrigada a realizar estorno ou
            ressarcimento por passagens (tickets/créditos) não utilizados
            qualquer seja a hipótese de rescisão.
          </p>
          <p>
            <b>6. DA MULTA CONTRATUAL</b>
          </p>
          <p>
            <b>6.1 </b>
            Na hipótese de rescisão por justa causa (cláusula 5.1, “b”), a
            CONTRATANTE será obrigada a pagar, a título de multa contratual, o
            valor correspondente a uma mensalidade.
          </p>
          <p>
            <b>7. DO CONSENTIMENTO</b>
          </p>
          <p>
            <b>7.1 </b> As Partes reconhecem expressamente a veracidade,
            autenticidade, integridade, validade e eficácia do constante e
            declarado neste instrumento em meio digital, segundo disposto nos
            artigos 219 e 225 do Código Civil, assim como expressam concordância
            que o presente poderá ser assinado de forma digital, eletrônica ou
            manuscrita.
          </p>
          <p>
            <b>7.2 </b> Fica certo, também, que as assinaturas das Partes não
            precisam necessariamente ser apostas na mesma página de assinaturas
            deste instrumento, e que a troca de páginas de assinaturas,
            assinadas escaneadas ou digitalizadas pode se dar inclusive mediante
            por simples aceite a termos, gerando documento eletrônico automático
            (como por exemplo ".pdf"), situação que é tão válida e produz os
            mesmos efeitos que eventual assinatura original de cada Parte aposta
            neste instrumento.
          </p>
          <p>
            <b>8. DO USO E TRATAMENTO DE DADOS PESSOAIS DAS PARTES</b>
          </p>
          <p>
            <b>8.1 </b>
            As Partes declaram que reconhecem que as informações coletadas para
            confecção deste contrato, tais como nome, RG, CPF, profissão,
            endereço, email, telefone, bem como dados bancários e de cartão de
            débito/crédito da CONTRATANTE serão armazenados para tratamento por
            parte da CONTRATADA, em plena conformidade com as condições da Lei
            nº 13.709/18 (Lei Geral de Proteção de Dados - LGPD).
          </p>
          <p>
            <b>8.2 </b> A CONTRATADA atua como controladora no tratamento
            referidos dados da CONTRATANTE (titular), nos termos do que dispõe o
            art. 5º da LGPD.
          </p>
          <p>
            <b>8.3 </b> Nos termos do art. 7º, I, II, III, V, VII, IX e X e 8º
            da LGPD, a CONTRATANTE manifesta consentimento para compartilhamento
            de seus dados tanto para a finalidade da boa e adequada prestação do
            serviço objeto do contrato, sobretudo para fins de controle interno
            operacional, financeiro, e fiscal por parte da CONTRATADA, bem como
            para fins de necessário compartilhamento com a METROPLAN, enquanto
            poder concedente, nos termos da legislação estadual vigente, bem
            como a demais órgãos vinculados à Administração Pública Direta e
            Indireta, sobretudo autoridades administrativas e judiciais no
            devido exercício de suas competências.
          </p>
          <p>
            <b>8.4 </b> A CONTRATANTE também manifesta consentimento e autoriza
            o compartilhamento de seus dados com terceiros legitimados a
            defender os interesses das Partes no exercício do presente contrato.
          </p>
          <p>
            <b>8.5 </b> Fica garantido à CONTRATANTE, nos termos do art. 9º da
            LGPD, a consulta facilitada e gratuita sobre detalhes gerais do
            tratamento, tais como a forma do tratamento e a integralidade e
            proteção de seus dados, mediante simples solicitação ao encarregado
            da CONTRATADA, por telefone (53) 3278-7000 (ramal 224) ou por email
            juridico@expressoembaixador.com.br.
          </p>
          <p>
            <b>8.6 </b>
            Os dados poderão ser armazenados e compartilhados no exercício de
            exigências legais mesmo após eventual rescisão do contrato,
            consoante as hipóteses legais de conservação, previstas no art. 16
            da LGPD.
          </p>
          <p>
            <b>8.7 </b>
            Nos termos do art. 18 da LGPD, a CONTRATANTE poderá solicitar
            livremente a exclusão de seus dados pessoais tratados mediante
            simples manifestação direcionada à CONTRATADA, na pessoa de seu
            encarregado.
          </p>
          <p>
            <b>9. DO FORO</b>
          </p>
          <p>
            <b>9.1 </b>
            As partes elegem o foro da comarca de Pelotas/RS, com exclusão de
            qualquer outro, para resolver qualquer litígio que surja em razão do
            presente contrato.
          </p>
        </div>

        <div className="text-end my-3">
          {dados?.aceite25
            ? moment(dados?.aceite25).format(
                "[Assinado digitalmente no dia] DD/MM/YYYY [às] HH:mm:ss."
              )
            : moment(dados?.aceite25).format(
                "[Pelotas, ] DD [de] MMMM [de] YYYY"
              )}
        </div>
      </div>
    </div>
  );
};

export default VerContrato;
