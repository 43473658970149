import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Button } from "react-bootstrap";

const Renovacao = () => {
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const handleAccept = async () => {
    navigate("../aceite");
  };

  const handleCancel = async () => {
    setLoading(true);

    await setDoc(doc(db, "ebserh2024", uid), { status: "c" }, { merge: true });

    alert("Contrato cancelado com sucesso!");

    navigate("../home");
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "ebserh2024", uid));

    const data = qry.data();

    if (data?.status === "a" && !data?.aceite25) {
      setDados(data);
      setLoading(false);
    } else {
      navigate("../home");
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      {!loading && (
        <Frame
          body={
            <div className="p-2">
              <h5>RENOVAÇÃO DE CONTRATO</h5>
              <hr />

              <p>O seu contrato atual encerrou no dia 31/07/2024.</p>

              <p>
                Com o pagamento da mensalidade no valor de R$ 600,00, ocorreu a
                renovação automática para o mês de agosto.
              </p>

              <div className="alert alert-warning" role="alert">
                A partir do mês de 01/09/2024, a mensalidade terá reajuste para
                o valor de R$ 620,00 (40 créditos).
              </div>

              <p>
                Para continuar utilizando os serviços, clique no botão azul e
                leia atentamente os novos termos na tela a seguir.
              </p>

              <p>
                Caso não deseje continuar utilizando o serviço, selecione o
                botão vermelho e o contrato será cancelado.
              </p>

              <p>Selecione uma das opções abaixo para prosseguir:</p>

              <Button variant="danger m-3 p-3" onClick={handleCancel}>
                Quero encerrar meu contrato
              </Button>

              <Button variant="primary m-3 p-3" onClick={handleAccept}>
                Quero renovar meu contrato
              </Button>
            </div>
          }
        />
      )}
    </>
  );
};

export default Renovacao;
