export const ValidaEmail = (email) => {
  if (!email) return "Digite seu email";
  else if (
    !email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  )
    return "Email inválido";
  else return "";
};

export const ValidaSenha = (senha) => {
  if (!senha) return "Digite sua senha";
  else if (senha.length < 6) return "Deve conter pelo menos 6 caracteres";
  else return "";
};

export const ValidaSenha2 = (senha, senha2) => {
  if (!senha2) return "Digite sua senha";
  else if (senha !== senha2) return "As senhas não são iguais";
  else return "";
};

export const ValidaLogin = (erro) => {
  let r = {};

  if (erro === "auth/invalid-email") r.email = "Email inválido";
  else if (erro === "auth/user-not-found") r.email = "Email não cadastrado";

  if (erro === "auth/internal-error") r.senha = "Senha inválida";
  else if (erro === "auth/wrong-password") r.senha = "Senha incorreta";
  else if (erro === "auth/too-many-requests")
    r.senha =
      "Conta bloqueada temporariamente. Tente novamente mais tarde ou redefina sua senha.";

  return r;
};

export const ValidaCadastro = (erro) => {
  let r = {};
  if (erro === "auth/invalid-email") r.email = "Email inválido";
  else if (erro === "auth/email-already-in-use")
    r.email = "Email já cadastrado";

  if (erro === "auth/internal-error") r.senha = "Senha inválida";
  else if (erro === "auth/weak-password") r.senha = "Mínimo de 6 caracteres";

  return r;
};

export const ValidaRedefinir = (erro) => {
  let r = {};

  if (erro === "auth/missing-email") r.email = "Digite seu email";
  else if (erro === "auth/invalid-email") r.email = "Email inválido";
  else if (erro === "auth/user-not-found") r.email = "Email não cadastrado";

  return r;
};

export const ValidaForm1 = (form) => {
  let erro = {};

  if (!form?.nome) erro.nome = "Preencha este campo";
  else if (form?.nome.length < 3) erro.nome = "Nome inválido";

  if (!form?.rg) erro.rg = "Preencha este campo";
  else if (form?.rg.length < 8 || form?.rg.length > 14) erro.rg = "RG inválido";

  if (!form?.cpf) erro.cpf = "Preencha este campo";
  else if (form?.cpf.length !== 11) erro.cpf = "CPF inválido";

  if (!form?.vinculo) erro.vinculo = "Escolha uma opção";

  if (!form?.fone1) erro.fone1 = "Preencha este campo";
  else if (form?.fone1.length < 8 || form?.fone1.length > 14)
    erro.fone1 = "Telefone inválido";
  else if (form?.fone1.length === 8 || form?.fone1.length === 9)
    erro.fone1 = "Insira o DDD também";

  if (!form?.fone2) {
  } else if (form?.fone2.length < 8 || form?.fone2.length > 14)
    erro.fone2 = "Telefone inválido";
  else if (form?.fone2.length === 8 || form?.fone2.length === 9)
    erro.fone2 = "Insira o DDD também";

  if (!form?.endereco1) erro.endereco1 = "Preencha este campo";

  if (!form?.numero1) erro.numero1 = "Preencha este campo";

  if (!form?.cep1) erro.cep1 = "Preencha este campo";
  else if (form?.cep1.length !== 8) erro.cep1 = "CEP inválido";

  if (!form?.bairro1) erro.bairro1 = "Preencha este campo";

  if (!Object.keys(erro).length) erro = "";

  return erro;
};

export const ValidaForm2 = (form) => {
  let erro = {};

  if (!form?.urlRg) erro.rg = "Envio obrigatório";

  if (!form?.urlVinculo) erro.vinculo = "Envio obrigatório";

  if (!form?.urlResidencia) erro.residencia = "Envio obrigatório";

  if (!form?.urlFoto) erro.foto = "Envio obrigatório";

  if (!Object.keys(erro).length) erro = "";

  return erro;
};

export const ValidaForm7 = (form) => {
  let erro = {};

  if (!form?.dataInicio) erro.dataInicio = "Informação obrigatória";

  if (!Object.keys(erro).length) erro = "";

  return erro;
};
